import httpUtil from "@/assets/script/utils/http.util"
import { Result } from "@/assets/script/model/main.model"
export interface WeChatUser {
  nickname: string
  headimgurl: string
  attentionTime: string
}

export interface Device {
  deviceId: string
  username: string
  activateTime: string
}

export class SleepDataService {
  static refreshUserAndDevice(): Promise<
    Result<{
      weChatUserList: WeChatUser[]
      deviceList: Device[]
    }>
  > {
    return httpUtil.get("/sleepData/refreshUserAndDevice")
  }
}
